<template>
  <b-modal id="userModal" ref="userModal" scrollable @hide="close">
    <template #modal-header>
      <h5>{{ $t("NEW_USER") }}</h5>
      <b-button size="sm" @click="resetModal()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.028"
          height="17.028"
          viewBox="0 0 17.028 17.028"
        >
          <path
            id="Icon_material-close"
            data-name="Icon material-close"
            d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
            transform="translate(-7.5 -7.5)"
            fill="#393939"
          />
        </svg>
      </b-button>
    </template>

    <form @submit.prevent="addCLI">
      <div class="center">
        <b-form-group :label="$t('SURNAME') + '*'">
          <b-form-input
            v-model="v$.newuser.prenom.$model"
            :state="validateState('prenom')"
            oninput="this.value = this.value.toUpperCase()"
            aria-describedby="prenom-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.newuser.prenom.$errors"
            id="prenom-feedback"
          ></error-handle>
          <div v-if="erreurlist.prenom" class="error-message">
            <ul v-if="Array.isArray(erreurlist.prenom)">
              <span v-for="(e, index) in erreurlist.prenom" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.prenom }}</span>
          </div>
        </b-form-group>
        <b-form-group :label="$t('NAME') + '*'">
          <b-form-input
            v-model="v$.newuser.nom.$model"
            :state="validateState('nom')"
            oninput="this.value = this.value.toUpperCase()"
            aria-describedby="nom-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.newuser.nom.$errors"
            id="nom-feedback"
          ></error-handle>
          <div v-if="erreurlist.nom" class="error-message">
            <ul v-if="Array.isArray(erreurlist.nom)">
              <span v-for="(e, index) in erreurlist.nom" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.nom }}</span>
          </div>
        </b-form-group>
        <b-form-group :label="$t('EMAIL') + '*'">
          <b-form-input
            v-model="v$.newuser.email_u.$model"
            type="email"
            :state="validateState('email_u')"
            aria-describedby="email_u-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.newuser.email_u.$errors"
            id="email_u-feedback"
          ></error-handle>
          <div v-if="erreurlist.email_u" class="error-message">
            <ul v-if="Array.isArray(erreurlist.email_u)">
              <span v-for="(e, index) in erreurlist.email_u" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.email_u }}</span>
          </div>
        </b-form-group>
        <b-form-group :label="$t('PASSWORD') + '*'">
          <b-form-input
            autocomplete="off"
            v-model="v$.newuser.password.$model"
            type="password"
            minlength="5"
            :state="validateState('password')"
            aria-describedby="password-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.newuser.password.$errors"
            id="password-feedback"
          ></error-handle>
          <div v-if="erreurlist.password" class="error-message">
            <ul v-if="Array.isArray(erreurlist.password)">
              <span v-for="(e, index) in erreurlist.password" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.password }}</span>
          </div>
        </b-form-group>
        <b-form-group :label="$t('PASSWORD_CONFIRM') + '*'">
          <b-form-input
            id="password_confirmation"
            autocomplete="off"
            v-model="newuser.password_confirmation"
            type="password"
            :state="validateState('password_confirmation')"
            aria-describedby="password_confirmation-feedback"
            required
          ></b-form-input>
          <error-handle
            :list="v$.newuser.password_confirmation.$errors"
            id="password_confirmation-feedback"
          ></error-handle>
          <div v-if="erreurlist.password_confirmation" class="error-message">
            <ul v-if="Array.isArray(erreurlist.password_confirmation)">
              <span
                v-for="(e, index) in erreurlist.password_confirmation"
                :key="index"
              >
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.password_confirmation }}</span>
          </div>
        </b-form-group>
        <b-form-group :label="$t('PHONE') + '*'">
          <template>
            <phone @phoneNumber="formatNumber($event)"></phone>
          </template>
          <div v-if="erreurlist.tel_u" class="error-message">
            <ul v-if="Array.isArray(erreurlist.tel_u)">
              <span v-for="(e, index) in erreurlist.tel_u" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.tel_u }}</span>
          </div>
          <div v-if="v$.newuser.tel_u.$error" class="error-message">
            {{ $t("REQUIRED_FIELD") }}
          </div>
          <div
            v-if="newuser.tel_u.phone_number && resultValid == false"
            class="error-message"
          >
            {{ $t("ERROR_PHONE") }}
          </div>
        </b-form-group>
        <b-form-group
          :label="$t('ROLE') + '*'"
          v-if="isSuperAdmin && getentreprise.id == 1"
        >
          <b-form-select
            required
            v-model="newuser.role"
            :options="optionsuper"
            id="type"
          ></b-form-select>
          <div v-if="erreurlist.role" class="error-message">
            <ul v-if="Array.isArray(erreurlist.role)">
              <span v-for="(e, index) in erreurlist.role" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.role }}</span>
          </div>
          <div v-if="v$.newuser.role.$error" class="error-message">
            {{ $t("REQUIRED_FIELD") }}
          </div>
        </b-form-group>
        <b-form-group :label="$t('ROLE')" v-else>
          <b-form-select
            required
            v-model="newuser.role"
            :options="options"
            id="type"
          ></b-form-select>
          <div v-if="erreurlist.role" class="error-message">
            <ul v-if="Array.isArray(erreurlist.role)">
              <span v-for="(e, index) in erreurlist.role" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.role }}</span>
          </div>
          <div v-if="v$.newuser.role.$error" class="error-message">
            {{ $t("REQUIRED_FIELD") }}
          </div>
        </b-form-group>
      </div>
    </form>

    <template #modal-footer>
      <b-alert variant="warning" show v-if="errorS">
        {{ errorS }}
      </b-alert>
      <div class="double">
        <b-button variant="danger" @click="resetModal()">
          <div class="block-spinner">
            {{ $t("CANCEL") }}
          </div>
        </b-button>
        <b-button variant="success" @click="addCLI">
          <div class="block-spinner">
            {{ $t("SAVE") }}
            <div v-if="getOnlineUserLoading" class="loading ml-2">
              <div class="spinner-border" role="status"></div>
            </div>
          </div>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  sameAs,
  minLength,
  email,
  maxLength,
} from "@vuelidate/validators";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import errorHandle from "../ui/errorHandle.vue";
import phone from "../ui/phone.vue";

export default {
  components: {
    errorHandle,
    phone,
  },
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  data() {
    return {
      newuser: {
        nom: null,
        prenom: null,
        email_u: null,
        tel_u: {
          phone_number: null,
          phone_iso_code: null,
        },
        password: null,
        password_confirmation: null,
        role: "user.assistant",
        entreprise: {
          id: null,
        },
      },
      options: [
        { value: "user.entreprise-admin", text: "Entreprise Admin" },
        { value: "user.assistant", text: "Assistant" },
        { value: "user.technicien", text: "Technicien" },
        { value: "user.comptable", text: "Comptable" },
        { value: "user.commercial-entreprise", text: "Commercial" },
      ],
      optionsuper: [
        { value: "user.entreprise-admin", text: "Entreprise Admin" },
        { value: "user.assistant", text: "Assistant" },
        { value: "user.technicien", text: "Technicien" },
        { value: "user.comptable", text: "Comptable" },
        { value: "user.commercial-entreprise", text: "Commercial" },
        { value: "user.commercial-batigo", text: "commercial batigo" },
      ],
      errorS: null,
      resultValid: null,
      box: "",
      vueTel: {
        preferredCountries: ["FR", "TN"],
        placeholder: "Enter your phone",
        mode: "auto",
        inputOptions: {
          showDialCode: true,
        },
        disabledFormatting: false,
        validCharactersOnly: true,
        wrapperClasses: "country-phone-input",
      },
      erreurlist: {
        nom: null,
        prenom: null,
        email_u: null,
        tel_u: null,
        password: null,
        password_confirmation: null,
        role: null,
      },
    };
  },
  validations() {
    return {
      newuser: {
        nom: { required, maxLength: maxLength(100) },
        prenom: { required, maxLength: maxLength(100) },
        email_u: { required, email, maxLength: maxLength(50) },
        tel_u: {
          phone_number: { required },
        },
        password: { required, minLength: minLength(6) },
        password_confirmation: {
          required,
          sameAs: sameAs(this.newuser.password),
        },
        role: { required },
      },
    };
  },
  methods: {
    ...mapActions([
      "store_user",
      "entreprise",
      "all_users",
      "pays",
      "all_entreprises",
    ]),

    validateState(name) {
      const { $dirty, $error } = this.v$.newuser[name];
      return $dirty ? !$error : null;
    },

    resetModal() {
      this.errorS = null;
      this.$refs["userModal"].hide();
      this.newuser = {
        nom: null,
        prenom: null,
        email_u: null,
        tel_u: {
          phone_number: null,
          phone_iso_code: null,
        },
        password: null,
        password_confirmation: null,
        role: "user.assistant",
        entreprise: {
          id: null,
        },
      };
      this.$nextTick(() => {
        this.v$.$reset();
      });
      this.erreurlist = {
        nom: null,
        prenom: null,
        email_u: null,
        tel_u: null,
        password: null,
        password_confirmation: null,
        role: null,
      };
    },

    close(bv) {
      if (this.$refs["userModal"].isShow) {
        if (bv.trigger == "backdrop") {
          bv.preventDefault();
          this.showMsgBox();
        }
      }
    },

    showMsgBox() {
      this.box = "";
      this.$bvModal
        .msgBoxConfirm(`${this.$t("CLOSE")}`, {
          title: `${this.$t("CONFIRM")}`,
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          cancelVariant: "danger",
          okTitle: `${this.$t("YES")}`,
          cancelTitle: `${this.$t("NO")}`,
          footerClass: "p-2",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.resetModal();
          }
        });
    },

    formatNumber(event) {
      this.newuser.tel_u = {
        phone_number: event.number,
        phone_iso_code: event.country.iso2,
      };
      this.resultValid = event.isValid;
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    async addCLI() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      if (this.resultValid == false) {
        return;
      }
      this.erreurlist = {
        nom: null,
        prenom: null,
        email_u: null,
        tel_u: null,
        password: null,
        password_confirmation: null,
        role: null,
      };
      this.errorS = null;
      if (!this.newuser.entreprise.id) {
        this.newuser.entreprise = this.getOnlineUser.entreprise.id;
      }
      if (this.isSuperAdmin)
        this.newuser.entreprise = this.$route.query.entreprise;

      // Format numero tel
      var num = this.newuser.tel_u.phone_number.indexOf(" ");
      this.newuser.tel_u = {
        phone_number: this.newuser.tel_u.phone_number
          .slice(num + 1)
          .split(" ")
          .join(""),
        phone_iso_code: this.newuser.tel_u.phone_iso_code,
      };

      await this.store_user(this.newuser)
        .then(() => {
          this.resetModal();
          this.$emit("updateList");
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorU = err;
          }
        });
    },
  },
  mounted() {
    if (this.$route.query.entreprise) {
      this.entreprise({ id: this.$route.query.entreprise });
    }
    if (!this.getAllentreprises) {
      if (this.isSuperAdmin) {
        this.all_entreprises({
          page: this.page,
          per_page: this.perPage,
        });
      }
    }
  },

  computed: {
    ...mapGetters([
      "getOnlineUserLoading",
      "getTotalRowuser",
      "getTabs",
      "getAllUsers",
      "getOnlineUser",
      "getAllentreprises",
      "getPays",
      "getSelectedLang",
      "getdictionnaire",
      "getentreprise",
    ]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },

    query() {
      return this.$route.query.entreprise;
    },

    translations() {
      var trans = {
        countrySelectorLabel: "Choisir un pays",
        phoneNumberLabel: "Numéro de téléphone",
      };
      this.getdictionnaire.forEach((element) => {
        if (element.key == "countrySelectorLabel")
          trans.countrySelectorLabel = element.lib;
        if (element.key == "phoneNumberLabel")
          trans.phoneNumberLabel = element.lib;
      });

      return trans;
    },
  },
};
</script>
